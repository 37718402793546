import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

const Availability = ({ status, stock = 0, allowOutOfStock }) => {
  if (status === "AVAILABLE") {
    if (stock === 0 && allowOutOfStock === false) return "Uitverkocht";
    else return "Bestel direct";
  }
  if (status === "COMMING_SOON") {
    return "Binnenkort verkrijgbaar";
  }
  return "Niet beschikbaar";
};

const ProductRollItem = ({ post, productData }) => {
  const productImage = post.frontmatter.images[0].image;

  return (
    <div className="is-parent column is-4">
      <article className={`blog-list-item tile is-child`}>
        <header>
          <Link to={post.fields.slug.slice(0, -1)}>
            {post.frontmatter.images[0].image ? (
              <div className="featured-thumbnail">
                <div className="featured-image">
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: productImage,
                      alt: `Afbeelding voor pakket ${post.frontmatter.title}`,
                      width: productImage.childImageSharp.gatsbyImageData.width,
                      height:
                        productImage.childImageSharp.gatsbyImageData.height,
                    }}
                  />
                </div>
                <div className={`featured-text`}>
                  €{post.frontmatter.price},-
                </div>
              </div>
            ) : null}
          </Link>
          <div className="post-meta">
            <span className="title white">{post.frontmatter.name}</span>
            <div
              className="is-flex margin-top-0 is-block"
              style={{ flexDirection: "column", gap: "1em" }}
            >
              {productData[post.frontmatter.productid].stock === 0 &&
              productData[post.frontmatter.productid].allowOutOfStock &&
              post.frontmatter.status === "AVAILABLE" ? (
                <Link
                  to={post.fields.slug.slice(0, -1)}
                  className="button is-primary"
                >
                  Nabestelling
                </Link>
              ) : (
                <button
                  className="snipcart-add-item button is-primary"
                  data-item-id={post.frontmatter.productid}
                  data-item-price={post.frontmatter.price.toFixed(2)}
                  data-item-description={post.frontmatter.description}
                  data-item-name={post.frontmatter.name}
                  data-item-custom1-name="Size"
                  data-item-custom1-options={
                    post.frontmatter.sizes === ""
                      ? null
                      : post.frontmatter.sizes
                  }
                  data-item-custom1-value={
                    post.frontmatter.sizes === "" ? null : "M"
                  }
                  data-item-image={
                    productImage &&
                    productImage.childImageSharp.gatsbyImageData.images.fallback
                      .src
                  }
                  data-item-quantity={1}
                  // data-item-max-quantity={
                  //   productData[post.frontmatter.productid].allowOutOfStock
                  //     ? 99
                  //     : productData[post.frontmatter.productid].stock
                  // }
                  disabled={
                    (productData[post.frontmatter.productid].stock === 0 &&
                      !productData[post.frontmatter.productid]
                        .allowOutOfStock) ||
                    post.frontmatter.status !== "AVAILABLE"
                  }
                >
                  <Availability
                    status={post.frontmatter.status}
                    stock={productData[post.frontmatter.productid].totalStock}
                    allowOutOfStock={
                      productData[post.frontmatter.productid].allowOutOfStock
                    }
                  />
                </button>
              )}
              <Link to={post.fields.slug.slice(0, -1)} className="button">
                {" "}
                Bekijk
              </Link>
            </div>
            <hr className="solid"></hr>
          </div>
        </header>
      </article>
    </div>
  );
};

const ProductRollTemplate = (props) => {
  const { data } = props;
  const { edges: posts } = data.allMarkdownRemark;

  // const [productData, setProductData] = useState({ stock: 0 });
  const [productData, setProductData] = useState(
    posts.reduce((acc, x) => {
      return { ...acc, [x.node.frontmatter.productid]: 0 };
    }, {})
  );
  useEffect(() => {
    getProductdata();
  }, []);

  async function getProductdata() {
    const secret = `${process.env.GATSBY_SNIPCART_PRIVATE_KEY}:`;
    await fetch(`https://app.snipcart.com/api/products`, {
      headers: {
        Authorization: `Basic ${btoa(secret)}`,
        Accept: "application/json",
      },
    })
      .then((request) => request.json())
      .then((data) => {
        let result = data.items.reduce((acc, x) => {
          return {
            ...acc,
            [x.userDefinedId]: {
              stock: x.stock,
              allowOutOfStock: x.allowOutOfStockPurchases,
              creationDate: x.creationDate,
            },
          };
        }, productData);
        setProductData(result);
      })
      .catch((err) => console.log(err));
  }

  return (
    <div className="columns is-multiline">
      {posts &&
        posts
          .sort((a, b) => {
            return (
              new Date(productData[b.node.frontmatter.productid].creationDate) -
              new Date(productData[a.node.frontmatter.productid].creationDate)
            );
          })
          .map(({ node: post }) => (
            <ProductRollItem
              post={post}
              key={post.id}
              productData={productData}
            />
          ))}
    </div>
  );
};

ProductRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default function ProductRoll() {
  return (
    <StaticQuery
      query={graphql`
        query ProductRollQuery {
          allMarkdownRemark(
            filter: { frontmatter: { templateKey: { eq: "product-post" } } }
          ) {
            edges {
              node {
                id
                fields {
                  slug
                }
                frontmatter {
                  name
                  price
                  productid
                  status
                  sizes
                  images {
                    image {
                      childImageSharp {
                        gatsbyImageData(
                          width: 500
                          height: 500
                          quality: 100
                          layout: CONSTRAINED
                        )
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data, count) => (
        <ProductRollTemplate data={data} count={count} />
      )}
    />
  );
}
