import React from "react";
import Layout from "../../components/Layout";
import { getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import ProductRoll from "../../components/ProductRoll";
import ScrollDivider from "../../components/ScrollDivider";
import Cart from "../../components/Cart";
import FullWidthMedia from "../../components/FullWidthMedia";

// eslint-disable-next-line
export default () => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "paketten.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  const heroImage = getImage(data.file);

  return (
    <Layout>
      <FullWidthMedia
        img={heroImage}
        video={
          "https://res.cloudinary.com/mol-meets/video/upload/q_auto:eco/f_auto/v1649780916/lustrum/Tijdlijn_Kloojo.mp4"
        }
        title={"Winkel"}
        subheading={"Krijg er dorst van!"}
        height={"80vh"}
        color="#E8E100"
        maxFont={800}
        darken={true}
      />
      <ScrollDivider color={"#861c37"} button={true} anchor="/winkel#main" />
      <Cart />
      <section
        className="section section--gradient"
        style={{ background: "#861c37" }}
      >
        <div className="container" id="main">
          <div className="content">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content">
                  <div className="columns">
                    <div className="column is-12">
                      <h3 className="has-text-weight-semibold is-size-3 is-size-5-mobile has-text-centered is-uppercase">
                        Lustrumartikelen
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="solid" id="main"></hr>
            <ProductRoll />
          </div>
        </div>
      </section>
    </Layout>
  );
};
